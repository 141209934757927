// requires additional assets to work
// <script src="https://cdn.merge.dev/initialize.js" type="text/javascript"></script>
import { mount } from '#js/components/utils'

export function initializeMergeLink (button) {
  button.disabled = true
  button.addEventListener('click', function () {
    window.MergeLink.openLink()
  })
  window.MergeLink.initialize({
    linkToken: button.dataset.linkToken,
    onSuccess: (publicToken) => onSuccess(publicToken, button),
    onReady: () => (button.disabled = false),
    onExit: () => (window.location.reload()),
    shouldSendTokenOnSuccessfulLink: true,
    tenantConfig: {
      apiBaseURL: 'https://api-eu.merge.dev'
    }
  })
}

export function onSuccess (publicToken, button) {
  const data = new FormData()
  data.append('csrfmiddlewaretoken', document.querySelector('input[name=csrfmiddlewaretoken]').value)
  data.append('merge_public_token', publicToken)
  fetch(button.dataset.linkSuccessUrl, {
    method: 'POST',
    body: data,
    credentials: 'same-origin'
  }).then((response) => {
    return response.json()
  }).catch((error) => {
    console.error(error)
  })
}

mount(initializeMergeLink, '#mergedev-link-button')
